import { MainMenuTypeEnum } from '@enums/main-screen/main-menu-type.enum';
import { SettingsMenuEnum } from '@enums/settings/settings.enum';

import { environment } from '../../../environments/environment';

export interface SuggestEmployeeParams {
  search: string;
  whitelistEmployeeId?: number;
  whitelistDepartmentId?: number;
  searchType?: 'not-in-ampm' | 'not-in-dep';
  departmentId?: number;
}

export class Urls {
  public static auth = {
    usersUrlIsPro: (search: string): string => `${environment.oogUrl}/auth/employees/find/suggest?search=${search}`,
    usersUrlAisSd: (search: string): string =>
      `${environment.securityMobileUrl}/security/search/login?searchText=${search}`,
    loginUrlAisSd: `${environment.securityMobileUrl}/security/login`,
    loginUrlIsPro: `${environment.oogUrl}/auth/login`,
    currentUserIsPro: `${environment.oogUrl}/api/auth/current`,
    currentUserAisSd: `${environment.mobileUrl}/principals`,
    logoutOib: `${environment.mobileUrl}/logout/oib`,
    logoutAisSd: `${environment.mobileUrl}/logout`,
    logoutIsPro: `${environment.oogUrl}/api/auth/logout`,
    saveDeviceInfoIsPro: `${environment.oogUrl}/api/mobile/device/save`,
    saveDeviceInfoAisSd: `${environment.mobileUrl}/device/save`,
    aisSdUserPermissions: `${environment.mobileUrl}/setting/all`,
    hasAssistant: `${environment.oogUrl}/api/auth/has-assistant`,
  };

  public static drafts = {
    /** Сохранить чернов резолюции */
    save: `${environment.oogUrl}/api/mobile/draft/save`,
  };

  public static pictures = {
    oogProfilePic: (photoId: string): string => `${environment.oogOibUrl}/api/v1/images/${photoId}`,
  };

  public static reports = {
    /** Подгрузить отчеты со всех департаментов */
    allFolders: `${environment.oogUrl}/api/mobile/reports/folders`,
    /** Залить отчет на сервер */
    uploadReport: `${environment.oogUrl}/api/mobile/reports/save`,
    /** Подгрузить список папок для данного департамента */
    foldersForDepartment: (departmentId): string => `${environment.oogUrl}/api/mobile/reports/folders/${departmentId}`,
    /** Отобразить отчет */
    showReport: (fileId: number) => `${environment.oogUrl}/api/mobile/file/preview/${fileId}`,
    /** Скачать отчет */
    downloadReport: (fileId: number) => `${environment.oogUrl}/api/mobile/file/download/${fileId}`,
  };

  public static delegating = {
    switchDelegating: (delegatingId: number): string =>
      `${environment.mobileUrl}/security/delegating/switch-to-delegating/${delegatingId}`,
    switchToYourself: `${environment.mobileUrl}/security/delegating/switch-to-yourself`,
    getDelegatings: `${environment.mobileUrl}/security/delegating/my`,
  };

  public static dictionaries = {
    /** Получить список срочности */
    urgency: `${environment.oogUrl}/api/dictionaries/urgencyreviewtypes`,
    /** Получить горячий список исполнителей */
    hotEmployee: (whitelistEmployeeId: number, whitelistDepartmentId: number): string =>
      `${environment.oogUrl}/api/mobile/frequently-used-executors${
        whitelistEmployeeId ? '?white-list-employee-id=' + whitelistEmployeeId : ''
      }${whitelistDepartmentId ? '&white-list-department-id=' + whitelistDepartmentId : ''}`,
    /** Получить список шаблонов */
    templates: `${environment.oogUrl}/api/mobile/resolution/resolution-templates`,
    /** Получить полный список срочности */
    allUrgency: `${environment.oogUrl}/api/dictionaries/checkdates`,
    /** Возвращает выходные дни в заданном промежутке времени */
    weekends: `${environment.oogUrl}/api/mobile/date/find-days-off?startDate=1900-07-01&endDate=2222-01-01`,
    /** Возвращает список специальных пользователей (мэр, суперюзеры) */
    specialEmployee: `${environment.oogUrl}/api/mobile/special-employee?code=MAYOR`,
    /** Получить необходимую дату при выборе срочности */
    deadLineDate: (id: number): string => `${environment.oogUrl}/api/mobile/date/deadlineByCheckDate/${id}`,
    /** Получить необходимую дату при выборе срочности с учетом автора */
    deadLineDateWithAuthor: (typeId: number, authorId: number): string =>
      `${environment.oogUrl}/api/mobile/date/deadlineByCheckDate/${typeId}?authorId=${authorId}`,
    /** Найти исполнителя */
    suggestEmployee: (params: SuggestEmployeeParams): string => {
      const { search, whitelistEmployeeId, whitelistDepartmentId, searchType, departmentId } = params;

      return `${environment.oogUrl}/api/mobile/dictionaries/employees/find/suggest?search=${search}&skip=0&take=100${
        whitelistEmployeeId ? '&white-list-employee-id=' + whitelistEmployeeId : ''
      }${whitelistDepartmentId ? '&white-list-department-id=' + whitelistDepartmentId : ''}${
        searchType ? '&searchType=' + searchType : ''
      }${departmentId ? '&departmentid=' + departmentId : ''}`;
    },
    /** Найти организацию */
    searchDepartment: `${environment.oogUrl}/api/mobile/dictionaries/departments/find/suggest`,
    getEmployeeBrief: (id: number): string => `${environment.oogUrl}/api/mobile/dictionaries/employees/brief/${id}`,
  };

  public static approvals = {
    /** Урл для получения листа согласования */
    getApprovalList: (id: number): string => `${environment.oogUrl}/api/mobile/approvals/${id}`,
    /** Урл для согласования с комментом или без */
    approveApproval: (id: number): string =>
      `${environment.oogUrl}/api/mobile/approvals/approve?appealMovementId=${id}`,
    /** Урл для получения блока "На номер" */
    getToNumber: (id: number): string => `${environment.oogUrl}/api/mobile/to-number/${id}`,
    /** Урл для получения резолюций для блока на № */
    getTree: (id: number): string => `${environment.oogUrl}/api/mobile/to-number/tree/${id}`,
    /** Урл для перенаправления согласования */
    redirectApproval: `${environment.oogUrl}/api/mobile/approvals/participant/redirect`,
    /** Урл для возврата согласования на доработку */
    returnApproval: `${environment.oogUrl}/api/mobile/approvals/comeback`,
    /** Урл для завершения согласования */
    cancelApprovalInDraft: (id: number): string => `${environment.oogUrl}/api/mobile/approvals/${id}`,
    /** Урл для направления согласования */
    directApprovalInDraft: (id: number): string => `${environment.oogUrl}/api/mobile/approvals/direct/${id}`,
  };

  public static notifications = {
    getNotifications: `${environment.oogUrl}/api/mobile/notice/`,
    deleteAll: `${environment.oogUrl}/api/mobile/notice/delete/all`,
    deleteOne: (id: number): string => `${environment.oogUrl}/api/mobile/notice/delete/${id}`,
  };

  public static oogEsign = {
    /** Урл для получения сертификатов */
    getCerts: `${environment.oogUrl}/api/mobile/esign/get-cloud-certificates`,
    /** Урл для подписания */
    createSign: `${environment.oogUrl}/api/mobile/esign/start-cloud-transaction`,
    /** Урл для сохранения настроек возможности подписания резолюций ЭП */
    resolutionEsignSettingsSave: `${environment.oogUrl}/api/mobile/resolution_esign_settings/save`,
    /** Урл для получения статуса подписания */
    signStatus: (transactionId: string): string =>
      `${environment.oogUrl}/api/mobile/esign/get-cloud-transaction-status/${transactionId}`,
    /** получение данных для подписи */
    prepareData: (): string => `${environment.oogUrl}/api/mobile/esign/prepare-data`,
    saveData: (appealMovementId: number): string =>
      `${environment.oogUrl}/api/mobile/esign/save-data?appealMovementId=${appealMovementId}`,
  };

  public static folders = {
    getFolders: (userId: number) => `${environment.oogUrl}/api/mobile/personal-folders/findAll/${userId}`,
    editOrCreateFolder: `${environment.oogUrl}/api/mobile/personal-folders/save`,
    deleteFolder: (folderId: number) => `${environment.oogUrl}/api/mobile/personal-folders/delete/${folderId}`,
    deleteAppealFromFolder: (folderId: number, appealId: number) =>
      `${environment.oogUrl}/api/mobile/personal-folders/deleteFromFolder/${folderId}/${appealId}`,
    moveToPersonalFolder: `${environment.oogUrl}/api/mobile/personal-folders/moveToFolder`,
    findPersonalFolderByDocument: (appealId: number, userId: number) =>
      `${environment.oogUrl}/api/mobile/personal-folders/findPersonalFoldersByAM/${appealId}/${userId}`,
    /** Получить документы в папку */
    getDocumentsInPersonalFolders: `${environment.oogUrl}/api/mobile/documents/personal-folders`,
  };

  public static mainScreen = {
    /** Сохранение пользователя, передавшего права в сессии */
    saveRightsToSession: (transferredRightId: number): string =>
      `${environment.oogUrl}/api/mobile/transfer-rights/save-session?onBehalfOfEmployeeId=${transferredRightId}`,
    /** Сброс пользователя, передавшего права в сессии */
    resetSessionRights: `${environment.oogUrl}/api/mobile/transfer-rights/save-session`,
    /** получение количества документов для замещаемых пользователей на главном экране */
    countsEmployeeFrom: `${environment.oogUrl}/api/mobile/appeals/counts-employee-from`,
    /** Сохранить работу за всех */
    saveWorkForEveryone: `${environment.oogUrl}/api/mobile/transfer-rights/save-session?onBehalfOfAll=true`,
  };

  /**  Урл для получения количества документов в категриях на главном экране */
  public static readonly mainScreenCategoriesUrl = `${environment.oogUrl}/api/mobile/appeals/countsAll`;

  /** Получить замещаемого пользователя */
  public static readonly replacedUser = `${environment.oogUrl}/api/mobile/transfer-rights/current`;

  public static resolutions = {
    /** Получить резолюцию */
    find: (id: number): string => `${environment.oogUrl}/api/mobile/resolution/find/${id}`,
    /** Получить список вышестоящих проектов */
    tree: (id: number): string => `${environment.oogUrl}/api/mobile/resolution/branch/${id}`,
    /** Утвердить проект резолюции */
    accept: (id: number): string =>
      `${environment.oogUrl}/api/mobile/resolution/accept-project-resolution?appealMovementId=${id}`,
    /** Создать резолюцию */
    create: (id: number): string =>
      `${environment.oogUrl}/api/mobile/resolution/createResolution?appealMovementId=${id}`,
    /** Получить список файлов */
    files: (id: number): string => `${environment.oogUrl}/api/mobile/file/list/${id}`,
    /** Получить конкретный файл */
    file: (id: number, appealMovementId: number): string =>
      `${environment.oogUrl}/api/mobile/file/preview/${id}?am=${appealMovementId}`,
    /** Предпросмотр файла */
    previewFile: `${environment.oogUrl}/api/mobile/file/preview`,
    /** Ссылки на файлы для кэширования */
    links: `${environment.oogUrl}/api/mobile/file/links`,
    /** Скачать конкретный файл */
    download: (id: number, appealMovementId: number): string =>
      `${environment.oogUrl}/api/mobile/file/download/${id}?am=${appealMovementId}`,
    /** Скачать склеенный файл */
    downloadMerged: (appealId: number, appealMovementId: number): string =>
      `${environment.oogUrl}/api/mobile/file/download-file-from-pages?appealId=${appealId}&appealMovementId=${appealMovementId}`,
    /** Загрузить файл */
    upload: `${environment.oogUrl}/api/mobile/file/upload`,
    /** Ознакомление с резолюцией */
    review: (id: number): string => `${environment.oogUrl}/api/mobile/resolution/confirm-review?appealMovementId=${id}`,
    /** Проверить все ли резолюции в группе просмотрены */
    isViewedAll: (id: number): string => `${environment.oogUrl}/api/mobile/resolution/group/${id}`,
    /** Идентификаторы просмотра резолюций */
    groupViewFlags: (id: number): string => `${environment.oogUrl}/api/mobile/resolution/group-view-flags/${id}`,
    /** Групповое перенаправление/создание резолюции */
    actionWithGroupResolution: (id: number): string =>
      `${environment.oogUrl}/api/mobile/group-actions/createResolution?appealMovementId=${id}`,
    /** Получить блок "Дело" */
    dossier: (id: number): string => `${environment.oogUrl}/api/mobile/resolution/get-data-dossiers/${id}`,
    /** Получить дерево для блока "Дело" */
    dossierTree: (id: number, fullHistory: boolean): string =>
      `${environment.oogUrl}/api/mobile/resolution/get-data-dossiers/tree/${id}?isFullHistory=${fullHistory}`,
    /** Получить список картинок */
    listImages: (id: number): string => `${environment.oogUrl}/api/mobile/file/list/pages/${id}`,
    /** Получить черновик резолюции */
    draft: (resolutionId: number): string =>
      `${environment.oogUrl}/api/mobile/draft/get-created-resolution?resolutionId=${resolutionId}`,
  };

  public static readonly menu = {
    [MainMenuTypeEnum.officialCorrespondence]: `${environment.oogUrl}/api/mobile/documents/official-correspondence`,
    [MainMenuTypeEnum.immediateCorrespondence]: `${environment.oogUrl}/api/mobile/documents/immediate-correspondence`,
    [MainMenuTypeEnum.urgentCorrespondence]: `${environment.oogUrl}/api/mobile/documents/urgent-correspondence`,
    [MainMenuTypeEnum.mayorMission]: `${environment.oogUrl}/api/mobile/documents/mayor-mission`,
    [MainMenuTypeEnum.projectsMission]: `${environment.oogUrl}/api/mobile/documents/mission-projects`,
    [MainMenuTypeEnum.projectsMail]: `${environment.oogUrl}/api/mobile/documents/mail-projects`,
    [MainMenuTypeEnum.officialDocuments]: `${environment.oogUrl}/api/mobile/documents/official-documents`,
    [MainMenuTypeEnum.actProjects]: `${environment.oogUrl}/api/mobile/documents/act-project`,
    [MainMenuTypeEnum.actImmediate]: `${environment.oogUrl}/api/mobile/documents/act-immediate`,
    [MainMenuTypeEnum.actUrgent]: `${environment.oogUrl}/api/mobile/documents/act-urgent`,
    [MainMenuTypeEnum.schedule]: `${environment.oogUrl}/api/mobile/documents/sign`,
    [MainMenuTypeEnum.makeDecision]: `${environment.oogUrl}/api/mobile/documents/make-decision`,
  };

  public static readonly settings = {
    /** Сохранение настроек уведомлений для текущего пользователя */
    [SettingsMenuEnum.notification]: `${environment.oogUrl}/api/mobile/settings/notice/save`,
    /** Сохранение настроек документов для текущего пользователя */
    [SettingsMenuEnum.document]: `${environment.oogUrl}/api/mobile/settings/doc/save`,
    /** Сохранение настроек замещения для текущего пользователя */
    [SettingsMenuEnum.substitute]: `${environment.oogUrl}/api/mobile/settings/sub/save`,
    /** Сохранение настроек по личным папкам */
    [SettingsMenuEnum.folders]: `${environment.oogUrl}/api/mobile/settings/personal-folder/save`,
    /** Сохранение настроек по отчётам */
    [SettingsMenuEnum.reports]: `${environment.oogUrl}/api/mobile/settings/reports/save`,
    [SettingsMenuEnum.mobileModeration]: `${environment.securityMobileUrl}/check-is-on-moderate`,
  };

  /** Получение информации о бекенде */
  public static readonly backendBuildInfo = {
    oog: `${environment.host}/oog-mobile/buildinfo`,
    npa: `${environment.host}/npa/build-info`,
  };

  public static readonly npaRouteAgreements = {
    /** Вернуть согласование */
    returnAgreement: (routeId: number): string => `${environment.mobileUrl}/route/${routeId}/internal-return`,
    /** Перенаправить согласование
     *
     * @param routeId
     * @param sourcePointId Идентификатор точки, из которой перенаправлено согласование.
     */
    redirectAgreement: (routeId: number, sourcePointId: number): string =>
      `${environment.mobileUrl}/route/${routeId}/redirect/${sourcePointId}`,
    /** Отправить на доработку Автору */
    returnToAuthor: (routeId: number): string => `${environment.mobileUrl}/route/${routeId}/return-to-author`,
    /** Направить руководителю на ОАУ */
    sendToOauLeader: (routeId: number): string => `${environment.mobileUrl}/route/${routeId}/send-to-oau-leader`,
  };

  public static readonly npaDashboard = {
    getStatistics: `${environment.mobileUrl}/dashboard/statistics`,
    getFilters: `${environment.mobileUrl}/dashboard/search/mobile-parameter`,
  };

  public static npaDocumentPackageEndpoints = {
    /** получить информацию о ПД */
    getDocumentPackage: (dpId: number): string => `${environment.mobileUrl}/documentPackage/${dpId}`,
    /** получить детальную информацию о ПД */
    getDocumentPackageAdditionalInfo: (dpId: number): string => `${environment.mobileUrl}/documentPackage/${dpId}/more`,
    getDocumentRoute: (dpId: number): string => `${environment.mobileUrl}/route/${dpId}/new-agreements`,
    /** получить данные завершающего пользователя */
    getFinalUserDataRoute: (): string => `${environment.mobileUrl}/employee/final-user/1`,
    /** Согласование пакета документов */
    approveDocumentPackage: (dpId: number): string => `${environment.mobileUrl}/documentPackage/${dpId}/approve`,
    /** Согласование пакета документов с замечаниями */
    approveDocumentPackageWithRemark: (dpId: number): string =>
      `${environment.mobileUrl}/documentPackage/${dpId}/approve-with-remark`,
    /** Получить данные для подписания документа */
    sign: (ldeId: string, ldeRevision: string) => `${environment.mobileUrl}/document/${ldeId}/${ldeRevision}/signData`,
    /** добавление точки после указанной
     *
     * @param routeId идентификатор маршрута
     * @param pid идентификатор точки после которой будет создана новая точка
     */
    addAfter: (routeId: number, pid: number): string => `${environment.mobileUrl}/route/${routeId}/add-after/${pid}`,
  };

  public static routeEndpoints = {
    /** Направить замечания без согласования */
    routeExternalReturn: (routeId: number): string => `${environment.mobileUrl}/route/${routeId}/external-return`,
    /** Взять в работу */
    startDevelopment: (routeId: number): string => `${environment.mobileUrl}/route/${routeId}/start-development/`,
    /** Отправить на согласование */
    startApprovement: (routeId: number): string => `${environment.mobileUrl}/route/${routeId}/start-approvement/`,
    /** добавление ознакомителя */
    addIntroducer: (routeId: number, pointId: number) =>
      `${environment.mobileUrl}/route/${routeId}/add-introducer/${pointId}`,
    hasDiff: (routeId: number) => `${environment.mobileUrl}/route/${routeId}/has-diff`,
    /** Отправить на внешнее согласование (с Проект создан Доработка) */
    startOuterApprovement: (routeId: number): string =>
      `${environment.mobileUrl}/route/${routeId}/send-to-outer-approvement`,
    /** Создать доработку для автора */
    createRework: (routeId: number) => `${environment.mobileUrl}/route/${routeId}/create-rework`,
    /** Начать доработку для автора */
    startRework: (routeId: number) => `${environment.mobileUrl}/route/${routeId}/start-rework`,
    /** Получение информации о этапах внешнего согласования */
    getOuterApprovementsInfo: (routeId: number) => `${environment.mobileUrl}/route/${routeId}/outer-approvements-info`,
  };

  public static npaDocumentEndpoints = {
    documentAlternatives: `${environment.mobileUrl}/documents/alternatives`,
    markDocumentPackageAsReadable: `${environment.mobileUrl}/read-document-packages`,
    searchByDocumentPackageId: `${environment.mobileUrl}/document/search/by-document-package-id`,
    exportRequest: `${environment.mobileUrl}/document/print`,
    postDownloadDocumentRequest: `${environment.mobileUrl}/document/export`,
    signableDocuments: `${environment.mobileUrl}/document/signable`,
  };

  public static npaEmployeeEndpoints = {
    /** Поиск сотрудников */
    getEmployees: `${environment.mobileUrl}/employee/search`,
    /** Поиск должностей */
    searchJobPositions: `${environment.mobileUrl}/employee/positions/search`,
    /** Поиск огранизаций */
    searchOrganizations: `${environment.mobileUrl}/employee/organizations/search`,
    /** Получить конкретную организацию по её идентификатору */
    getOrganization: (id: number) => `${environment.mobileUrl}/employee/organizations/${id}`,
    /** Получить должность по идентификатору */
    getJobPositionById: (id: number): string => `${environment.mobileUrl}/employee/positions/${id}`,
    findEmployees: () => `${environment.mobileUrl}/employee/findEmployee`,
  };

  public static npaEsignEndpoints = {
    login: `${environment.mobileUrl}/cryptopro-dss/auth`,
    getCerts: `${environment.mobileUrl}/cryptopro-dss/certificates`,
  };

  public static npaDocumentPackagesEndpoints = {
    /** Подтверждение ознакомления */
    approveIntroduction: (dpId: number): string => `${environment.mobileUrl}/document-packages/${dpId}/introduce`,
    /** Подтверждение ознакомления c комментарием */
    approveIntroductionWithComment: (dpId: number | string): string =>
      `${environment.mobileUrl}/document-packages/${dpId}/send-comment`,
  };

  /** Руководство пользователя */
  public static readonly userGuide = `${environment.oogOibUrl}/rp`;

  /** Ускоренное рассмотрение */
  public static expeditedReview = {
    get: `${environment.oogUrl}/api/mobile/accelerated-review/accelerated-projects/all`,
    /** Утвердить все проекты */
    acceptAll: `${environment.oogUrl}/api/mobile/accelerated-review/accelerated-projects/acceptAll`,
    /** Включение/отключение ускоренного рассмотрения */
    switch: (value: boolean, id: number): string =>
      `${environment.oogUrl}/api/mobile/accelerated-review/project-resolution/switch?isOnAcceleratedReview=${value}&resolutionId=${id}`,
    /** Получить право на просмотр УР из настроек ИС ПРО */
    rightExpeditedReview: `${environment.oogUrl}/api/mobile/accelerated-review/right`,
  };

  /** Получить список ИО и текущего пользователя */
  public static readonly getCurrentEmployeeFrom = `${environment.oogUrl}/api/mobile/discharge-of-duties/current`;

  public static backendStatus = {
    checkConnectionOog: `${environment.oogUrl}/actuator/health`,
    checkConnectionNpa: `${environment.host}/mo/npa/actuator/health`,
    // checkConnectionNpa: `${environment.host}/npa/actuator/health`,
  };

  /** API для работы с закладками */
  public static bookmarks = {
    create: `${environment.oogUrl}/api/mobile/bookmark`,
    delete: (id: number): string => `${environment.oogUrl}/api/mobile/bookmark/${id}`,
  };

  public static readonly appStoreUrl = environment.appStoreUrl;

  /** Получить список ИО по выбранному пользователю */
  public static readonly dischargeOfDuties = (id: number): string =>
    `${environment.oogUrl}/api/mobile/discharge-of-duties/current-for/${id}`;
}
