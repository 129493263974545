import { EnvironmentModel } from '@models/environment.model';
import envInfo from '../../package.json';

const host = 'https://oog-tst-mo.it2g.ru';

export const environment: Readonly<EnvironmentModel> = {
  production: false,
  mobileVersion: envInfo?.version,
  envName: 'test',
  host,
  oogUrl: `${host}/oog-mobile`,
  oogOibUrl: 'https://oog-mo-stg2.it2g.ru',
  npaEnabled: true,
  baseUrl: `${host}/mo/npa/api`,
  mobileUrl: `${host}/mo/npa/api/mobile`,
  securityMobileUrl: `${host}/mo/npa/mobile`,
  authorizationToOib: `https://aissd-oib.it2g.ru/aissd-access/login?service=${host}/mo/npa/oib-auth`,
  npaWebsocketHost: `${host}`,
  npaWebsocketUrl: '/npa/socket',
  ldeUrl: 'lde-api/api/v1/npa',
  isproUrl: 'http://oog-stg.it2g.ru',
  aissdUrl: 'http://isusdpm-internal.it2g.ru',
  inCaseOfUnavailabilityRedirectToHost: '',
  enableSentry: false,
  sentryDsn: 'https://c23460f69a78408f8f1cf3aca99663da@oog-tst-mo.it2g.ru/sentry/2',
  appStoreUrl: 'itms-apps://apps.apple.com/ru/app/%D0%BC%D0%BE%D1%81%D0%BE%D1%84%D0%B8%D1%81/id1546857767?l=ru',
  updateDelay: 300000,
};
